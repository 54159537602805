import React, { useState } from 'react'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Box from '@mui/material/Box'
import { getColor } from '../../config/helpers/Helper'

export default function InputQuestion(props) {
    return (
        <Grid container alignItems='center' justifyContent='flex-start' spacing={1} sx={{ textAlign: 'left' }}>
            <Grid xs={12}>
                <h3 style={{ marginBottom: '1rem' }}>{props.question}</h3>
            </Grid>
            <Grid xs={12} sm={12}>
                <TextField 
                    //label='Last Name' 
                    color={getColor(props.site)}
                    variant='outlined' 
                    type='number'
                    value={props.answer} 
                    placeholder={props.placeholder}
                    size='large'
                    onChange={(e) => props.updateAnswer(e.target.value)}
                    style={{width: '100%'}}
                    inputProps = {
                        {
                            sx: {
                                'fontSize': '1.2rem',
                                '&::placeholder': {
                                    color: 'rgb(121, 118, 118)',
                                    opacity: 1
                                },
                            },
                        }
                    }
                    disabled={props.disabled}
                />
            </Grid>
        </Grid>
    )
}