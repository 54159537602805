import axios from 'axios'

export async function getElectricityPlans() {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://sheetdb.io/api/v1/e45ifq49ue12v?sheet=Electricity'
        })
        //console.log('response: ', response.data)
        return response.data
    } catch(e) {
        console.log(e)
        return 'error'
    }
}

export async function getGasPlans() {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://sheetdb.io/api/v1/e45ifq49ue12v?sheet=Gas'
        })
        //console.log('response: ', response.data)
        return response.data
    } catch(e) {
        console.log(e)
        return 'error'
    }
}

export async function getDualPlans() {
    try {
        const response = await axios({
            method: 'get',
            url: 'https://sheetdb.io/api/v1/e45ifq49ue12v?sheet=Dual'
        })
        //console.log('response: ', response.data)
        return response.data
    } catch(e) {
        console.log(e)
        return 'error'
    }
}

export async function calculateResults(data) {
    try {
        const response = await axios({
            method: 'post',
            url: process.env.NODE_ENV === 'development' ? 'https://iecrsg73c7.execute-api.eu-west-1.amazonaws.com/dev/comparison/calculate' : 'https://of9o52h4hl.execute-api.eu-west-1.amazonaws.com/prod/comparison/calculate',
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        })
        console.log(response)
        return response.data
    } catch (e) {
        console.error(e)
        return 'error'
    }
}