import React, { useState, useEffect, useRef } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import Divider from '@mui/material/Divider'
import Button from '@mui/material/Button'
import Calculating from '../Comparison/Calculating'
import ElectricityForm from '../Comparison/ElectricityForm'
import GasForm from '../Comparison/GasForm'
import DualForm from '../Comparison/DualForm'
import Results from '../Comparison/Results'
import OptionsQuestion from '../Comparison/OptionsQuestion'
import ResizeObserver from 'resize-observer-polyfill'
import * as api from '../../config/api/ComparisonAPI'
import { supplyOptions } from '../../config/constants/Constants'
import { 
    getSite,
    validateFormData,
    getStandardPlanForCustomDiscount,
} from '../../config/helpers/Comparison'
import '../../comparison.css'

const loadTime = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm() {
    const site = getSite(window.location.href)
    const [form, setForm] = useState('Electricity')
    const [sameSupplier, setSameSupplier] = useState('Yes')
    const [stage, setStage] = useState('calculator')
    const [formData, setFormData] = useState({
        'electricity': {
            region: 'Urban',
            tariffType: '24hr',
            nightsaverUsage: 'No, use National Average',
            nightsaverUsageExact: 38,
            electricityUsage: 'No, use National Average',
            electricityUsageExact: 4200
        },
        'gas': {
            gasUsage: 'No, use National Average',
            gasUsageExact: 11000
        },
        'gas & electricity': {
            region: 'Urban',
            tariffType: '24hr',
            nightsaverUsage: 'No, use National Average',
            nightsaverUsageExact: 38,
            electricityUsage: 'No, use National Average',
            electricityUsageExact: 4200,
            gasUsage: 'No, use National Average',
            gasUsageExact: 11000
        }
    })
    const [allPlans, setAllPlans] = useState({
        'electricity': [],
        'gas': [],
        'gas & electricity': []
    })
    const [results, setResults] = useState({})
    const [initialLoad, setInitialLoad] = useState(true)
    const [loading, setLoading] = useState(false)
    // results details
    const [includeSmartPlans, setIncludeSmartPlans] = useState('Yes')
    const [includeCashback, setIncludeCashback] = useState('Yes')

    const wrapperRef = useRef(null)
    const resultsRef = useRef(null)
    const currentHeight = useRef(0)

    useEffect(() => {
        getPlans()
    }, [])

    async function getPlans() {
        try {
            const electricityPlans = await api.getElectricityPlans()
            console.log('electricity plans: ', electricityPlans.length)

            const gasPlans = await api.getGasPlans()
            console.log('gas plans: ', gasPlans.length)

            const dualPlans = await api.getDualPlans()
            console.log('dual plans: ', dualPlans.length)
            
            setAllPlans((prevData) => ({
                ...prevData,
                'electricity': electricityPlans, // Directly set the fetched plans
                'gas': gasPlans,
                'gas & electricity': dualPlans
            }))
            // check url to get site
            // const site = getSite(window.location.href)
            // console.log('site: ', site)
            // setSite(site)
            setInitialLoad(false)
        } catch(e) {
            console.log('Error getting plans: ', e)
        }
    }

    // Send the height to the parent page
    const sendHeight = () => {
        if (wrapperRef.current) {
            const newHeight = wrapperRef.current.offsetHeight // Measure height of wrapper
            if (newHeight !== currentHeight.current) {
                currentHeight.current = newHeight
                window.parent.postMessage({ type: 'resizeIframe', height: newHeight }, '*')
            }
        }
    }

    const scrollToTop = () => {
        window.parent.postMessage({ type: 'scrollToTop'}, '*')
    }

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            sendHeight()
        });

        // Observe the wrapper element
        if (wrapperRef.current) {
            resizeObserver.observe(wrapperRef.current)
            sendHeight()
        }

        return () => {
            resizeObserver.disconnect()
        }
    }, [])

    useEffect(() => {
        if (loading === true) {
            scrollToTop()
        }
        if (stage === 'results') {
            scrollToTop()
        }
    }, [stage, loading])

    function updateFormData(formName, updatedData) {
        setFormData((prevData) => ({
            ...prevData,
            [formName]: { ...prevData[formName], ...updatedData },
        }))
    }

    function prepareCalculationData() {
        const data = formData[form.toLowerCase()];
        const allPlansForForm = allPlans[form.toLowerCase()];

        const selectedPlan = data.planId === 'custom'
            ? getStandardPlanForCustomDiscount(data.supplier, data.region, data.tariffType, allPlansForForm)
            : allPlansForForm.find(plan => plan['ID'] === data.planId);

        let planCopy = { ...selectedPlan };
        if (data.planId === 'custom') {
            planCopy['Discount'] = data.customDiscount;
        }

        // Calculate usage details
        const nightsaverPercentage = parseFloat(data.nightsaverUsageExact) / 100 || 0.38;
        const electricityTotal = parseFloat(data.electricityUsageExact) || 4200;
        const gasTotal = parseFloat(data.gasUsageExact) || 11000;

        return {
            form: form.toLowerCase(),
            sameSupplier,
            allPlans: allPlansForForm,
            selectedPlan: planCopy,
            nightsaverPercentage,
            electricityTotal,
            gasTotal,
            tariffStart: data.tariffStart,
            includeSmartPlans,
            includeCashback
        }
    }
    
    async function submitForm() {
        console.log('Submitting form with data: ', formData);
        setLoading(true);

        // Validate form data
        const errors = validateFormData(form, formData, sameSupplier)
        if (errors.length > 0) {
            alert(`Please complete the following fields:\n${errors.join("\n")}`)
            setLoading(false)
        }

        // Get results from API
        try {
            const requestData = prepareCalculationData();
            console.log('request data: ', requestData);
            
            const response = await api.calculateResults(requestData);
            console.log('results: ', response)
            
            setResults(response);
            setStage('results');
            setLoading(false);
            await loadTime(5000);
        } catch (error) {
            console.error('Error calculating results:', error);
        }
    }

    return (
        <div ref={wrapperRef} style={{padding: '2%'}} className='comparison-form' id={site}>
            {(stage === 'calculator' && !loading) &&
                <>
                <Grid container justifyContent='center' className='question-container'>
                    <Grid xs={10} style={{marginBottom: '2rem'}}>
                        <Divider className='calc-section-divider'><h2>Your Supply</h2></Divider>
                    </Grid>
                    <Grid xs={10} className='question'>
                        <OptionsQuestion
                            question='What would you like to compare?'
                            options={supplyOptions}
                            answer={form}
                            updateAnswer={(form) => setForm(form)}
                            site={site}
                            xs={6}
                            sm={4}
                            disabled={initialLoad}
                        />
                    </Grid>
                    {form === 'Gas & Electricity' &&
                        <Grid xs={10} className='question'>
                            <OptionsQuestion
                                question='Is your Gas & Electricity from the same supplier?'
                                options={[
                                    {value: 'Yes', label: 'Yes', disabled: false},
                                    {value: 'No', label: 'No', disabled: true}
                                ]}
                                answer={sameSupplier}
                                updateAnswer={(sameSupplier) => setSameSupplier(sameSupplier)}
                                site={site}
                                xs={12}
                                sm={6}
                            />
                        </Grid>
                    }
                </Grid>
                {form === 'Electricity' &&
                    <ElectricityForm
                        data={formData.electricity}
                        plans={allPlans.electricity}
                        onUpdate={(data) => updateFormData('electricity', data)}
                        updateIncludeSmartPlans={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                        site={site}
                        initialLoad={initialLoad}
                    />
                }
                {form === 'Gas' &&
                    <GasForm
                        data={formData.gas}
                        plans={allPlans.gas}
                        onUpdate={(data) => updateFormData('gas', data)}
                        updateIncludeSmartPlans={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                        site={site}
                        initialLoad={initialLoad}
                    />
                }
                {(form === 'Gas & Electricity' && sameSupplier === 'Yes') && 
                    <DualForm
                        data={formData['gas & electricity']}
                        plans={allPlans['gas & electricity']}
                        onUpdate={(data) => updateFormData('gas & electricity', data)}
                        updateIncludeSmartPlans={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                        site={site}
                        initialLoad={initialLoad}
                    />
                }
                <Grid container justifyContent='center' >
                    <Grid xs={10} style={{marginBottom: '2rem'}}>
                        <Divider className='calc-section-divider'><h2>Your Results</h2></Divider>
                    </Grid>
                    {/* hide if tariff type contains 'Smart' ? */}
                    <Grid xs={10} className='question'>
                        <OptionsQuestion
                            question='Include smart plans in results?'
                            options={
                                [
                                    {value: 'Yes', label: 'Yes', disabled: false},
                                    {value: 'No', label: 'No', disabled: false}
                                ]
                            }
                            answer={includeSmartPlans}
                            updateAnswer={(includeSmartPlans) => setIncludeSmartPlans(includeSmartPlans)}
                            site={site}
                            xs={12}
                            sm={6}
                        />
                    </Grid>
                    <Grid xs={10} className='question'>
                        <OptionsQuestion
                            question='Include cashback in calculations?'
                            options={
                                [
                                    {value: 'Yes', label: 'Yes', disabled: false},
                                    {value: 'No', label: 'No', disabled: false}
                                ]
                            }
                            answer={includeCashback}
                            updateAnswer={(includeCashback) => setIncludeCashback(includeCashback)}
                            site={site}
                            xs={12}
                            sm={6}
                        />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' alignItems='center'>
                    <Grid xs={11} display='flex' justifyContent={'center'} alignItems='center' className='buttons'>
                        <Button color={site} variant='contained' style={{width: 300, height: 60, fontSize: '1.1rem'}} onClick={() => submitForm()}>Find Cheaper Plans</Button>
                    </Grid>
                </Grid>
                </>
            }
            
            {stage === 'results' &&
                <Results
                    form={form}
                    results={results}
                    ref={resultsRef}
                    setStage={(stage) => setStage(stage)}
                    site={site}
                />
            }
            
            {loading && 
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '600px'}}>
                            <Calculating 
                                message='Finding plans that can save you money...'
                                form={form}
                            />
                        </Box>
                    </Grid>
                </Grid>
            }
        </div>
    )
}