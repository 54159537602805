import React, { useState, useRef, useEffect } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import MostImportant from '../Questions/MostImportant'
import HowMuch from '../Questions/HowMuch'
import Timeline from '../Questions/Timeline'
import ElectricBills from '../Questions/ElectricBills'
import Stories from '../Questions/Stories'
import GrantInterest from '../Questions/GrantInterest'
import Eircode from '../Questions/Eircode'
import Name from '../Questions/Name'
import Email from '../Questions/Email'
import Phone from '../Questions/Phone'
import Invalid from '../Invalid'
import Calculating from '../Calculating'
import ProgressBar from 'react-bootstrap/ProgressBar'
import * as api from '../../config/api/GuestAPI'
import { eircodesToCounty, validEmail } from '../../config/constants/Constants'
import { calculateFormProgress, redirect, solarRedirect } from '../../config/helpers/Helper'

const wait = t => new Promise((resolve, reject) => setTimeout(resolve, t))

export default function DataCaptureForm(props) {
    const [stage, setStage] = useState(0)
    const [loading, setLoading] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)
    const [invalid, setInvalid] = useState()
    const [invalidReason, setInvalidReason] = useState('')
    const [emailError, setEmailError] = useState(false)
    const [eircodeError, setEircodeError] = useState(false)
    // solar details
    const [mostImportant, setMostImportant] = useState('')
    const [howMuch, setHowMuch] = useState('')
    const [timeline, setTimeline] = useState('')
    const [diverterOrBattery, setDiverterOrBattery] = useState('')
    const [electricBills, setElectricBills] = useState('')
    const [stories, setStories] = useState('')
    const [roofType, setRoofType] = useState('')
    const [hasGrantInterest, setHasGrantInterest] = useState('')
    // personal details
    const [eircode, setEircode] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [privacyPolicy, setPrivacyPolicy] = useState('')
    // A/B Testing
    const [formVariant, setFormVariant] = useState(null)

    const dcForm = useRef(null)

    useEffect(() => {
        // Randomly select Form A or Form B with a 50% chance for each
        const randomForm = Math.random() < 0.5 ? 'roofSuitability' : 'topInstallers'
        console.log('form variant: ', randomForm)
        setFormVariant(randomForm)
    }, [])

    async function incrementStartForm() {
        try {
            api.incrementAbVariant('energyefficiency-01', formVariant + 'Total')
        } catch(e) {
            console.log('Error incrementing total: ', e)
        }
    }

    async function submitForm(e) {
        setLoading(true)
        if (e) {
            e.preventDefault()
        }

        const details = {
            mostImportant,
            howMuch,
            timeline,
            diverterOrBattery,
            electricBills,
            stories,
            roofType,
            hasGrantInterest,
            eircode,
            firstName,
            lastName,
            email,
            phone,
            privacyPolicy: true,
            formVariant
        }
        console.log('submit details: ', details)
        
        try {
            let ip = await api.getIp()
            let response = await api.submitEeForm(details, ip)

            // Add delay before redirect
            await wait(7000)
            
            if (response.isValid || firstName.toLowerCase() === 'test') {
                let provider = response.companyId ? response.companyId.toLowerCase() : ''
                console.log('provider: ', provider)
                solarRedirect('https://energyefficiency.ie/solar-recommendation', provider, response.hashedName, response.hashedEmail)
            }
            else {
                handleInvalidResponse(response)
            }
        } catch(error) {
            console.error('Error submitting form: ', e)
        }
    }

    function handleInvalidResponse(response) {
        switch (response.invalidReason) {
            case 'Profanity':
            case 'Fake Name':
            case 'Famous Name':
                // Optional: Send troll details
                // const trollResponse = await api.sendTrollDetails(details.firstName, details.lastName, details.email, details.phone, response.invalidReason)
                // console.log('Troll response:', trollResponse)
                redirect('https://youtu.be/GM-e46xdcUo?t=28')
                break
            default:
                setInvalid(true)
                if (['Phone number prefix', 'Phone number repeat', 'Phone number sequential'].includes(response.invalidReason)) {
                    setInvalidReason('Phone')
                } 
                else if (response.invalidReason.includes('Duplicate in last')) {
                    setInvalidReason('Duplicate')
                }
                setLoading(false)
        }
    }

    function checkEmail() {
        let trimmed = email.trim()
        console.log('trimmed email: ', trimmed)
        if (validEmail.test(trimmed)) {
            updateStage(stage + 1)
            setEmailError(false)
        }
        else {
            setEmailError(true)
        }
    }

    function updateStage(newStage) {
        setStage(newStage)
        //dcForm.current.scrollIntoView()
    }

    function checkEircode() {
        setButtonLoading(true)
        let county = null
        let prefix = eircode.trim().substring(0, 3).toUpperCase();
        console.log('prefix: ', prefix);
        if (eircodesToCounty.hasOwnProperty(prefix)) {
            county = eircodesToCounty[prefix];
        }
        if (county && county.length) {
            updateStage(stage + 1) 
            setEircodeError(false)
        }
        else {
            setEircodeError(true)
        }
        setButtonLoading(false)
    }

    function nextStage() {
        if (stage === 6) {
            checkEircode()
        }
        else if (stage === 8) {
            checkEmail()
        }
        else {
            setStage(stage + 1)
        }
    }

    function checkNextDisabled() {
        if (stage === 0) {
            return !mostImportant
        }
        if (stage === 1) {
            return !howMuch
        }
        if (stage === 2) {
            return !electricBills
        }
        if (stage === 3) {
            return !timeline
        }
        // if (stage === 4) {
        //     return !diverterOrBattery
        // }
        if (stage === 4) {
            return !stories
        }
        // if (stage === 6) {
        //     return !roofType
        // }
        if (stage === 5) {
            return !hasGrantInterest
        }
        if (stage === 6) {
            return !eircode
        }
        if (stage === 7) {
            return !firstName || !lastName
        }
        if (stage === 8) {
            return !email
        }
        if (stage === 9) {
            return !phone
        }
        if (stage === 10) {
            return !privacyPolicy
        }
    }

    function updateAnswer1(mostImportant) {
        setMostImportant(mostImportant)
        incrementStartForm()
    }

    return (
        <>
        {loading ? (
            <Grid container justifyContent='flex-start'>
                <Grid item xs={12}>
                    <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                        <Calculating 
                            message='Finding the best solar installer in your area...'
                        />
                    </Box>
                </Grid>
            </Grid>
        ) : (
            <>
            {invalid ? (
                <Grid container justifyContent='flex-start'>
                    <Grid item xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='center' style={{height: '100vh'}}>
                            <Invalid 
                                reason={invalidReason}
                            />
                        </Box>
                    </Grid>
                </Grid>
            ): (
            <form ref={dcForm} onSubmit={(e) => e.preventDefault()} style={{height: '100vh', padding: '2%'}} className='energyefficiency'>
                <Grid container justifyContent='center' className='progress-bar-container scroll-container'>
                    <Grid xs={11}>
                        <ProgressBar animated variant='success' now={calculateFormProgress(stage, 9, 7)} />
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='question-container scroll-container'>
                    <Grid xs={10} className='question'>
                        {stage === 0 &&
                            <MostImportant 
                                answer={mostImportant}
                                updateAnswer={(mostImportant) => updateAnswer1(mostImportant)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {stage === 1 &&
                            <ElectricBills 
                                answer={electricBills}
                                updateAnswer={(electricBills) => setElectricBills(electricBills)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {stage === 2 &&
                            <HowMuch 
                                answer={howMuch}
                                updateAnswer={(howMuch) => setHowMuch(howMuch)}
                                nextStage={() => nextStage()}
                                electricBills={electricBills}
                                site='energyefficiency'
                            />
                        }
                        {stage === 3 &&
                            <Timeline 
                                answer={timeline}
                                updateAnswer={(timeline) => setTimeline(timeline)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {/*stage === 4 &&
                            <DiverterOrBattery 
                                answer={diverterOrBattery}
                                updateAnswer={(diverterOrBattery) => setDiverterOrBattery(diverterOrBattery)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        */}
                        {stage === 4 &&
                            <Stories 
                                answer={stories}
                                updateAnswer={(stories) => setStories(stories)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {/*stage === 5 &&
                            <RoofType 
                                answer={roofType}
                                updateAnswer={(roofType) => setRoofType(roofType)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        */}
                        {stage === 5 &&
                            <GrantInterest 
                                answer={hasGrantInterest}
                                updateAnswer={(hasGrantInterest) => setHasGrantInterest(hasGrantInterest)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {stage === 6 &&
                            <Eircode 
                                eircode={eircode}
                                setEircode={(eircode) => setEircode(eircode)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                                formVariant={formVariant}
                                hasError={eircodeError}
                            />
                        }
                        {stage === 7 &&
                            <Name 
                                firstName={firstName}
                                lastName={lastName}
                                setFirstName={(firstName) => setFirstName(firstName)}
                                setLastName={(lastName) => setLastName(lastName)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                            />
                        }
                        {stage === 8 &&
                            <Email 
                                email={email}
                                setEmail={(email) => setEmail(email)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                                hasError={emailError}
                            />
                        }
                        {stage === 9 &&
                            <Phone 
                                phone={phone}
                                setPhone={(phone) => setPhone(phone)}
                                nextStage={() => nextStage()}
                                site='energyefficiency'
                                finalStep
                            />
                        }
                        {/*stage === 10 &&
                            <PrivacyPolicy 
                                privacyPolicy={privacyPolicy}
                                setPrivacyPolicy={(privacyPolicy) => setPrivacyPolicy(privacyPolicy)}
                                agree={() => submitForm()}
                                site='energyefficiency'
                            />
                        */}
                    </Grid>
                </Grid>
                <Grid container justifyContent='center' className='bottom-container scroll-container'>
                    <Grid xs={11} display='flex' justifyContent={stage > 0 ? 'space-between' : 'flex-end'} alignItems='center' className='buttons'>
                        {stage > 0 && 
                            <Button variant='outlined' size='large' onClick={() => updateStage(stage - 1)}>Back</Button>
                        }
                        {stage < 9 && 
                            <Button disabled={checkNextDisabled()} variant='contained' size='large' onClick={() => nextStage()}>Next</Button>
                        }
                        {stage === 9 &&
                            <Button disabled={checkNextDisabled()} variant='contained' size='large' onClick={() => submitForm()}>Agree & Submit</Button>
                        }
                    </Grid>
                </Grid>
            </form>
            )}
            </>
        )}
        </>
    )
}