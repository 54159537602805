import moment from 'moment'

export function getSite(url) {
    const siteMap = {
        'https://calculator.energyefficiency.ie/energycomparison': 'energyefficiency',
        'https://calculator.energyswitch.ie/energycomparison': 'energyswitch',
    }

    return siteMap[url] || 'energyefficiency'
}

export function getStandardPlanForCustomDiscount(supplier, region, tariffType, allPlans) {
    const standardPlan = allPlans.find(p =>
        p['Supplier'].trim().toLowerCase() === supplier.trim().toLowerCase() &&
        p['Region'].trim().toLowerCase() === region.trim().toLowerCase() &&
        p['Tariff Type'].trim().toLowerCase() === tariffType.trim().toLowerCase() &&
        parseFloat(p['Discount'].replace('%', '')) === 0
    )

    if (!standardPlan) {
        console.error('Standard plan not found')
        return 0
    }

    return standardPlan
}

export function getTariffStartOptions(earliestDate, endDate) {
    let options = []
  
    // Handle 'Earliest Date' special cases
    if (earliestDate === 'N/A') {
        return null // Hide the question completely
    }

    if (earliestDate === 'Over 12 Months Ago') {
        options.push({label: 'Over 12 months ago', value: 'Over 12 months ago'})
        // Add the last 12 months to the options
        let current = moment().subtract(11, 'months').startOf('month') // Start 11 months ago
        for (let i = 0; i < 12; i++) {
            options.push({
                label: current.format('MMMM YYYY'), 
                value: current.format('D MMMM YYYY')
            })
            current.add(1, 'months')
        }
        return options
    }

    // Parse earliest date if it's a valid month-year format
    let parsedEarliestDate = moment(earliestDate, 'D MMMM YYYY', true)
    if (!parsedEarliestDate.isValid()) {
        console.warn('Invalid Earliest Date:', earliestDate)
        return null // Hide the question completely if parsing fails
    }

    // Parse end date if available
    let parsedEndDate
    if (endDate === 'Now' || endDate === 'N/A') {
        parsedEndDate = moment() // Use current date if 'Now' or 'N/A'
    } 
    else {
        parsedEndDate = moment(endDate, 'D MMMM YYYY', true)
        if (!parsedEndDate.isValid()) {
            console.warn('Invalid End Date:', endDate)
            parsedEndDate = moment() // Default to current date if parsing fails
        }
    }
    console.log('Parsed Earliest Date:', parsedEarliestDate.format('D MMMM YYYY'))
    console.log('Parsed End Date:', parsedEndDate.format('D MMMM YYYY'))

    // Ensure the date range is valid (earliestDate should not be after endDate)
    if (parsedEarliestDate.isAfter(parsedEndDate)) {
        console.warn('Earliest date is after end date. Returning empty options.')
        return []
    }

    // Determine the date range for options
    let current = moment(parsedEarliestDate)
    while (current.isSameOrBefore(parsedEndDate)) {
        options.push({
            label: current.format('MMMM YYYY'), 
            value: current.format('D MMMM YYYY')
        })
        // after first month default to start of month
        current.add(1, 'months').startOf('month')
    }

    console.log('Generated Options:', options)
    return options
}

export function validateFormData(form, formData, sameSupplier) {
    const errors = []
  
    if (form === 'Electricity') {
        const { planId, supplier, region, tariffType } = formData['electricity']
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Electricity Supplier')
        if (!region) errors.push('Electricity Region')
        if (!tariffType) errors.push('Electricity Tariff Type')
    }
  
    if (form === 'Gas') {
        const { planId, supplier, paymentMethod } = formData['gas']
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Gas Supplier')
        if (!paymentMethod) errors.push('Gas Payment Method')
    }
  
    if (form === 'Gas & Electricity' && sameSupplier === 'Yes') {
        const { planId, supplier, region, tariffType } = formData['gas & electricity']
        if (!planId) errors.push('Tariff Name')
        if (!supplier) errors.push('Dual Fuel Supplier')
        if (!region) errors.push('Dual Fuel Region')
        if (!tariffType) errors.push('Dual Fuel Tariff Type')
    }
  
    if (form === 'Gas & Electricity' && sameSupplier === 'No') {
        const { elecPlanId, elecSupplier, region, tariffType } = formData.electricity
        const { gasPlanId, gasSupplier, paymentMethod } = formData.gas
        
        if (!elecPlanId) errors.push('Electricity Tariff Name')
        if (!elecSupplier) errors.push('Electricity Supplier')
        if (!region) errors.push('Electricity Region')
        if (!tariffType) errors.push('Electricity Tariff Type')
        if (!gasPlanId) errors.push('Gas Tariff Name')
        if (!gasSupplier) errors.push('Gas Supplier')
        if (!paymentMethod) errors.push('Gas Payment Method')
    }
  
    return errors
}